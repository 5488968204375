.toast {
  margin: auto;
  font-size: 22px;
  position: fixed;
  right: 36%;
  top: 12%;
}
.toast-body {
  padding: 0.5rem 1rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
}
.toast-header {
  padding: 0rem 0.75rem;
}
.toast {
  background-color: #c83232;
  color: #ffffff;
  opacity: 1;
}
.fade.toast.success {
  background-color: #008000;
  color: #ffffff;
}
.success .toast-header {
  background-color: #e6a851;
  color: #000000;
  opacity: 1;
  padding: 0.5rem 1rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  font-size: 22px;
  border: none;
}
.toast-header {
  background-color: #c83232;
  color: #ffffff;
}
.toast .close {
  color: #ffffff;
  opacity: 1;
}
.success .toast-header .toast .close {
  color: #000000 !important;
  opacity: 1;
}

/*    new    */
.fade.success-modal.modal.show .modal-content .modal-header {
  /*background: #e6a851!important;
    color: #000000!important;*/
  border-radius: 4px;
}
.fade.success-modal .modal-header {
  border: none !important;
}
.fade.success-modal .modal-dialog {
  max-width: 550px !important;
  max-height: 100%;
}
h6.mb-0.warning-msg-title {
  font-size: 18px;
  letter-spacing: 0.03rem;
}
.fade.success-modal.modal.show .modal-dialog {
  margin: 8.75rem auto !important;
}
.fade.warning-modal.modal.show .modal-dialog {
  margin: 8.75rem auto !important;
}
/*.fade.success-modal .modal-content {
    background-color: #fff0;
}*/

.fade.warning-modal.modal.show .modal-content .modal-header {
  /*background: #c83232!important;
    color: #ffffff!important;*/
  border-radius: 4px;
}
.fade.warning-modal .modal-header {
  border: none !important;
}
.fade.warning-modal .modal-dialog {
  max-width: 550px !important;
  max-height: 100%;
}
.fade.warning-modal button.close {
  color: #000000;
  opacity: 10;
}
/*.fade.warning-modal .modal-content {
    background-color: #fff0;
}*/
.fade.success-modal.modal.show {
  background: #000000bd;
}
.fade.warning-modal.modal.show {
  background: #000000bd;
}

.custom-alert .alert-demo {
  position: fixed !important;
  top: 0;
  z-index: 9999;
  width: 100%;
}

.alert-primary {
  width: 100%;
}

.alert.alert-primary.alert-dismissible button {
  position: absolute;
  right: 30px;
}
.custom-alert button {
  top: 10px;
  position: absolute;
  right: 30px;
}