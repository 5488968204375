$primary-color: #e6a851;
@import "loaders.css/src/animations/ball-grid-pulse.scss";

.full {
  background-color: #000000;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
}

.LoaderClass {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: fadeIn 0.5s; /* Safari 4+ */
  -moz-animation: fadeIn 0.5s; /* Fx 5+ */
  -o-animation: fadeIn 0.5s; /* Opera 12+ */
  animation: fadeIn 0.5s; /* IE 10+, Fx 29+ */

  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
